import "core-js/modules/es.array.push.js";
import { ElMessageBox, ElMessage, ElLoading } from 'element-plus';
import 'element-plus/es/components/message-box/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

import { mixins } from "@/plugins/mixins";
export default {
  name: "Area",
  mixins: [mixins],
  data() {
    return {
      currentDept: null,
      bindUserDialog: false,
      columns: [{
        label: '区域编码',
        prop: 'code',
        align: 'left'
      }, {
        label: '区域名称',
        prop: 'name'
      }, {
        label: '创建人',
        prop: 'userName'
      }],
      topButtons: [{
        name: '新增',
        code: 'add',
        type: 'primary',
        click: this.areaAdd,
        permissions: ['area_add']
      }],
      linkButtons: [{
        name: '删除',
        code: 'area_del',
        click: this.areaDel,
        permissions: ['area_del']
      }, {
        name: '修改',
        code: 'area_edit',
        click: this.areaEdit,
        permissions: ['area_edit']
      }],
      searchItems: []
    };
  },
  methods: {
    areaAdd() {
      this.$router.push('/areaAdd');
    },
    areaEdit(row) {
      this.$router.push({
        path: '/areaAdd',
        query: {
          id: row.id
        }
      });
    },
    areaDel(row) {
      ElMessageBox.confirm('确认要删除该区域吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.uac.area.del(row.id).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    }
  }
};